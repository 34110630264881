import React from 'react'
import PropTypes from 'prop-types'
import Plate from '../models/Plate'

import PriceLeft from '../assets/icons/price-left.svg'
import PriceRight from '../assets/icons/price-right.svg'

const MenuItem = ({ plate }) => {
  return (
    <div className="menu-item my-2 py-2 px-2 bi-avoid print:max-w-lg print:mx-auto">
      <h4 className="text-xl print:text-base leading-7 font-semibold text-center px-6">
        { plate.name() }
      </h4>
      <div className="text-center print:text-sm text-gray-900 italic px-2 my-3">
        { plate.description() }
      </div>
      <div className="text-center text-lg print:text-sm mt-4 flex items-center justify-center" style={{display:'none'}}>
        <PriceLeft className="mr-3 print:text-gray-200" />
        { plate.hasBothPrices() ? (
          <div>
            <span className="mr-1">{ plate.smallPricePrefix() }</span>
            <span>€ { plate.smallPrice() }</span>
            <span className="px-2">/</span>
            { plate.hasMediumPrice() && (
              <span>
                <span className="mr-1">{ plate.mediumPricePrefix() }</span>
                <span>€{ plate.mediumPrice() }</span>
                <span className="px-2">/</span>
              </span>
            )}
            <span className="mr-1">{ plate.normalPricePrefix() }</span>
            <span>€ { plate.normalPrice() }</span>
          </div>
        ) : (
          <span>€ { plate.normalPrice() }</span>
        )}
        <PriceRight className="ml-3 print:text-gray-200" />
      </div>
      { plate.hasAllergens() ? (
        <div className="flex items-center justify-center my-4">
          { plate.allergens().map((allergen, index) => (
            <img
              key={ index }
              src={ allergen.icon() }
              alt={ allergen.name() }
              title={ allergen.name() }
              width="42"
              height="42"
              className="w-12 h-12 mx-4 rounded-full"
            />
          )) }
        </div>
      ) : null }
    </div>
  )
}

MenuItem.propTypes = {
  plate: PropTypes.instanceOf(Plate)
}

export default MenuItem
