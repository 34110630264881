import React from 'react'
import MenuItem from './menuItem'

const MenuList = ({ list }) => (
  <>
    {list
      .filter(plate => plate.isVisible())
      .sort((a, b) =>
        a.attributes.id > b.attributes.id
          ? 1
          : (b.attributes.id > a.attributes.id ? -1 : 0)
      )
      .map((plate, index) => (
        <MenuItem plate={plate} key={index} />
      )
      )}
  </>
)

MenuList.defaultProps = {
  list: []
}

export default MenuList
