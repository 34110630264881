import React from 'react'
import { FormattedMessage } from "gatsby-plugin-intl"

import PriceLeft from '../assets/icons/price-left.svg'
import PriceRight from '../assets/icons/price-right.svg'

const CoverChargeInfo = () => (
  <div className="my-16 px-6 print:my-8">
    <h3 id="pane-e-coperto" className="text-4xl print:text-3xl font-dancing text-center mb-16">
      <FormattedMessage id="pane_e_coperto" />
    </h3>
    <div className="text-center text-lg flex items-center justify-center">
      <PriceLeft className="mr-3" />
      <span>€2,00</span>
      <PriceRight className="ml-3" />
    </div>
    <div className="text-center">
      <FormattedMessage id="a_persona" />
    </div>
    <div className="text-center text-gray-900 italic my-12 print:max-w-lg print:mx-auto print:text-sm">
      <div className="mb-8">
        <FormattedMessage id="disclaimer_prodotti_impiegati" />
      </div>
      <div>
        <FormattedMessage id="disclaimer_allergeni" />
      </div>
    </div>
  </div>
)

export default CoverChargeInfo
