import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { IntlContextConsumer } from "gatsby-plugin-intl"

import MenuList from './menuList'
import Course from '../models/Course'
import Separator from '../assets/icons/separator.svg'

const Courses = () => {
  const { courses } = useStaticQuery(graphql`
    query Courses {
      courses: allAirtable(filter: {table: {eq: "portate"}}, sort: {fields: data___id }) {
        nodes {
          recordId
          id
          data {
            nome
            traduzione {
              data {
                it
                de
                en
              }
            }
            note {
              data {
                it
                de
                en
              }
            }
            piatti {
              data {
                id
                mostra
                nome
                nome_en
                nome_de
                descrizione_en
                descrizione_it
                descrizione_de
                prezzo_grande
                prezzo_medio
                prezzo_piccolo
                prefix_prezzo_grande {
                  data {
                    it
                    en
                    de
                  }
                }
                prefix_prezzo_medio {
                  data {
                    it
                    en
                    de
                  }
                }
                prefix_prezzo_piccolo {
                  data {
                    it
                    en
                    de
                  }
                }
                allergeni {
                  data {
                    nome
                    traduzione {
                      data {
                        it
                        en
                        de
                      }
                    }
                    icona {
                      url
                      thumbnails {
                        full {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <IntlContextConsumer>
        {({ language }) => (
          <div>
            { courses.nodes.map((portata, index) => {
              const course = new Course(portata, language)
              return (
                <div className="portata" key={index}>
                  <div className="bi-avoid ba-avoid">
                    <Separator className="w-full h-auto px-6 my-16 print:my-8 print:max-w-xs print:mx-auto" />
                    <h3 id={course.slug()} className="text-4xl print:text-3xl font-dancing text-center mb-16 print:mb-8">
                      {course.name()}
                    </h3>
                  </div>
                  <MenuList list={course.plates()} />
                  { course.notes() ? (
                    <div className="italic text-center px-6 text-gray-900 print:max-w-lg print:mx-auto print:text-sm">
                      { course.notes()}
                    </div>
                  ) : null}
                </div>
              )
            })
            }
          </div>
        )}
      </IntlContextConsumer>
    </>
  )
}

export default Courses
