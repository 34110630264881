import React from "react"
import { graphql } from 'gatsby'
import { useIntl, FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Courses from "../components/courses"
import CoverChargeInfo from "../components/coverChargeInfo"

import Separator from '../assets/icons/separator.svg'

export default ({ data: { icon } }) => {
  const { formatMessage } = useIntl()

  return (
    <Layout footer={(
      <div className="text-center font-dancing text-5xl text-shadow leading-none my-6">
        <FormattedHTMLMessage id="buon_appetito" />
      </div>
    )} icon={icon}>
      <SEO title={ formatMessage({ id: 'menu_title' }) } />
      <div className="text-center">
        <h1 className="font-dancing text-6xl text-shadow">
          <FormattedMessage id="menu" />
        </h1>
        <h2 className="font-dancing text-4xl">
          <FormattedMessage id="casera_le_rotte" />
        </h2>
      </div>
      <div className="my-12 print:my-8 px-3">
        <Courses />
        <div className="bi-avoid">
          <Separator className="w-full h-auto px-6 my-16 print:my-8 print:max-w-xs print:mx-auto" />
          <CoverChargeInfo />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query MenuPageImage {
  icon: file(relativePath: { eq: "casette.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
